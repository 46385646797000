<!--
  12/4/22 - Forked from domo.com components
  This could definitely use a re-write
 -->

<template>
  <span
    class="TooltipBase"
    @mouseenter.self="openTip"
    @mouseleave="closeTip"
    @click.stop="toggleTip">

    <slot
      name="trigger">
      <IconBase
        name="question-bubble"
        width="16"
        height="16"
      />
    </slot>

    <div
      class="tooltip"
      v-show="displayTip"
      :style="computedPopperStyle">
      <span
        class="arrow"
        :style="computedArrowStyle">
      </span>

      <slot/>

    </div>

  </span>
</template>


<script>
import IconBase from '@/components/IconBase.vue';

export default {
  name: 'TooltipBase',

  components: {
    IconBase,
  },

  data() {
    return {
      displayTip: false,
      maxWidth: 320,
      pad: 20,
      box: {},
      windowWidth: 0,
    };
  },

  computed: {
    availableRight() {
      return Math.min(
        ((this.windowWidth - (this.pad * 2)) - ((this.box.left - this.pad) + (this.box.width / 2))),
        (this.maxWidth / 2),
      );
    },

    availableLeft() {
      return Math.min(((this.box.left - this.pad) + (this.box.width / 2)), (this.maxWidth / 2));
    },

    getLeft() {
      return (this.availableLeft < this.availableRight)
        ? (this.availableLeft - (this.box.width / 2))
        : (this.getWidth - this.availableRight) - (this.box.width / 2);
    },

    getWidth() {
      return Math.min((this.windowWidth - (this.pad * 2)), this.maxWidth);
    },

    computedPopperStyle() {
      return {
        width: `${this.getWidth}px`,
        left: `-${this.getLeft}px`,
      };
    },

    computedArrowStyle() {
      return {
        left: `${this.getLeft + 2}px`,
      };
    },
  },

  mounted() {
    window.addEventListener('resize', this.handleWindowResize);
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
  },

  methods: {
    toggleTip() {
      return !this.displayTip
        ? this.openTip()
        : this.closeTip();
    },

    closeTip() {
      this.displayTip = false;
    },

    openTip() {
      this.displayTip = true;
      this.box = this.$el.getBoundingClientRect();
      this.windowWidth = window.innerWidth;
    },

    handleWindowResize() {
      this.box = this.$el.getBoundingClientRect();
      this.windowWidth = window.innerWidth;
    },
  },
};
</script>


<style lang="scss" scoped>
@import '@/styles/_variables.scss';

$tooltipBgColor: $white;
$tooltipTriangleWidth: 10px;

.TooltipBase {
  user-select: none;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  .tooltip {
    display: block;
    white-space: normal !important;
    position: absolute;
    bottom: 100%;
    text-align: left;
    z-index: 500;
    padding: 15px 20px 20px 20px;
    border-radius: 8px;
    background: $white;
    box-shadow: 0 16px 48px -4px rgba($black, 0.4);
    transform: translateY(-12px);
    transition: transform .3s ease, opacity .3s ease;
    @include bp-iPad {
      pointer-events: none !important;
    }
    .arrow {
      position: absolute;
      bottom: -$tooltipTriangleWidth;
      left: 0;
      width: $tooltipTriangleWidth;
      height: $tooltipTriangleWidth;
      border-left: $tooltipTriangleWidth solid transparent;
      border-right: $tooltipTriangleWidth solid transparent;
      border-top: $tooltipTriangleWidth solid $tooltipBgColor;
    }
  }
}
</style>
